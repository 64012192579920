.cell-value-green {
  background-color: #a9cc9e;
}

.cell-value-red {
  background-color: #e6b8b8;
}

.selected-ticket {
  background-color: orangered;
  display: block;
  width: max-content;
  padding: 3px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 7px;
  color: white;
  cursor: pointer;
}

.selected-container {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1100px;
}

.error-box {
  background-color: #e6b8b8;
  padding: 10px;
  border-radius: 7px;
  margin: 0px auto;
  color: #9e1c1c;
  font-weight: 500;
  font-size: 1.1rem;
  max-width: 1100px;
  width: 100%;
}

.ag-theme-alpine {
  max-height: 500px; /* set a maximum height for the container */
}

.selected {
  background-color: green !important;
}

.display-tickets {
   height: auto !important;
   max-height: none !important;
   overflow: visible !important;
}


/* Establece el ancho de cada boleto para evitar el scroll horizontal */
.ticket {
  width: 100%; /* Ocupa todo el ancho disponible */
  border-radius: 10px;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.2);
}


/* Agrega estilos adicionales según sea necesario para los boletos 

/* Estilos para el contenedor de la lista de tickets */
.ticket-list-container {
  background-color: #f9f9f9;
  border-radius: 30px;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto !important;  /* Permite que el contenedor crezca */
  max-height: none !important; /* Elimina el límite de altura */
  overflow: visible !important; /* Asegura que el contenido se expanda */
}


.dialog-button-whatsapp {
  background-color: #25d366; /* Color verde de WhatsApp */
  color: white; /* Color de texto blanco */
  padding: 10px 20px; /* Espaciado interno del botón */
  border: none; /* Quita el borde */
  border-radius: 5px; /* Borde redondeado */
  cursor: pointer; /* Cursor al pasar el ratón */
  transition: background-color 0.3s ease; /* Transición suave del color de fondo */
}

.dialog-button-whatsapp:hover {
  background-color: #128c7e; /* Cambia el color de fondo al pasar el ratón */
}

.button-container {
  display: flex;
  justify-content: center;
}
